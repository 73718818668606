<template>
  <!-- <div > -->
  <div class="framePage-scroll">
    <div class="ovy-a">
      <h3 style="padding-bottom: 20px">基本信息</h3>
      <el-form
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="120px"
        class
      >
        <el-form-item label="班级名称：" prop="projectName" class="form-item">
          <el-input
            v-model="Form.projectName"
            placeholder="请输入班级名称"
            :disabled="disabled"
            size="small"
            maxlength="50"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="行政区划：" prop="areaId" class="form-item">
          <el-cascader
            v-model="Form.areaId"
            :options="areatreeList"
            :props="propsarea"
            :disabled="disabled"
            clearable
            filterable
            @change="areaChange"
            size="small"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="有效日期：" prop="Effectivedate" class="form-item">
          <el-date-picker
            size="small"
            :disabled="disabledcc"
            v-model="Form.Effectivedate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="报名截止时间：" prop="deadline" class="form-item">
          <el-date-picker
            size="small"
            :disabled="signEndDateDisabled"
            v-model="Form.deadline"
            type="date"
            format="yyyy-MM-dd"
            value-format="timestamp"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="负责人：" prop="projectUser" class="form-item">
          <el-input
            v-model="Form.projectUser"
            placeholder="请输入负责人"
            :disabled="disabled"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="负责人电话："
          prop="projectUserPhone"
          class="form-item"
        >
          <el-input
            v-model="Form.projectUserPhone"
            placeholder="请输入负责人电话"
            maxlength="12"
            :disabled="disabled"
            size="small"
          />
        </el-form-item>
        <el-form-item label="培训类型：" prop="Trainingtype" class="form-item">
          <el-input
            v-if="
              auditState == '30' || auditState == '20' || projectSource == '20'
            "
            v-model="Form.Trainingtype"
            type="text"
            size="small"
            :disabled="true"
          />
          <tree
            v-else
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="Form"
            size="small"
            @clearParams="clearParams"
            modal
            type
            typeStu
          />
        </el-form-item>
        <el-form-item
          label="培训人数"
          prop="projectPeople"
          class="form-item"
          v-if="selectType != '0'"
          required
        >
          <el-input-number
            v-model="Form.projectPeople"
            @change="handleChange"
            :min="1"
            style="width: 100%"
            :disabled="disabled"
            size="small"
          ></el-input-number>
        </el-form-item>

        <el-form-item
          label="期次"
          prop="projectPc"
          class="form-item"
          v-if="selectType != '0'"
        >
          <el-input
            type="text"
            v-model="Form.projectPc"
            :disabled="disabled"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="人群类型"
          prop="projectCrowd"
          class="form-item"
          v-if="selectType == '4'"
        >
          <el-select
            v-model="Form.projectCrowd"
            placeholder="请选择"
            :disabled="disabled"
            size="small"
          >
            <el-option
              v-for="item in CrowdtypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <h3 style="padding-bottom: 20px">基本设置</h3>
      <div style="display: flex; flex-direction: column">
        <div>
          <span style="padding: 0 15px; width: 13rem; text-align: right"
            >允许学员查看学时证明</span
          >
          <el-radio-group v-model="Form.projectClassHourCertificate">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div style="padding: 15px 0" v-show="Form.auditState == '40'">
        <h3 style="padding-bottom: 10px">审核事由</h3>
        <el-input
          type="textarea"
          disabled
          v-model="Form.auditReason"
        ></el-input>
      </div>

      <div class="bottom-btn">
        <el-button @click="cancle" class="bgc-bv">取消</el-button>
        <el-button @click="seveData('Form')" class="bgc-bv">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import tree from "@/components/treePopup";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import List from "@/mixins/List";
export default {
  name: "TestPaper",
  components: {
    tree,
  },
  mixins: [UploadPic, DatePicker, List],
  data() {
    var proTypeFrom = (rule, value, callback) => {
      var state = this.selectType;
      if (state == 2) {
        if (value == "") {
          callback(new Error("请选择人群类型"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的电话号码"));
      } else {
        callback();
      }
    };

    return {
      disabled: false,
      disabledcc: false,
      paperId: false,
      editJson: {},
      areatreeList: [],
      datatreeList: [], //培训类型
      CrowdtypeList: [],
      props: {
        value: "id",
        label: "label",
      },
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      selectType: "0",
      Form: {
        projectId: "",
        projectName: "",
        areaId: "",
        Effectivedate: [],
        deadline: "",
        projectUser: "",
        projectUserPhone: "",
        projectPeople: "",
        projectAddress: "",
        projectPc: "",
        projectPaperComp: "",
        Examinationtime: "",
        proType: "",
        thumbnail: "",
        fileKey: "",
        Trainingtype: "",
        projectClassHourCertificate: true,
        enableSurvey: false,
        auditState: "",
        auditReason: "",
        spotCheckNum: "",
        delay: false,
        chatFileKeys: [],
        trainMethod: "",
        uploadfileList: [],
      },
      TrainingMethodsList: [],
      uploadFileList: [],
      ruleForm: {
        Trainingtype: "",
      },
      params: {},
      rules: {
        projectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        deadline: [
          { required: true, message: "请选择报名截止时间", trigger: "blur" },
        ],
        projectUser: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        projectUserPhone: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],
        projectCrowd: [
          { required: true, message: "请选择人群类型", trigger: "change" },
        ],
        Trainingtype: [
          {
            required: true,
            message: "请选择培训类型",
            trigger: ["change", "blur"],
          },
        ],
      },
      projectSource: "",
      fileType: "",
      signEndDateDisabled: false,
    };
  },
  created() {
    this.editJson = this.$route.query;
    // console.log(this.editJson);
    this.projectId = sessionStorage.getItem("projectId");
    this.auditState = sessionStorage.getItem("auditState");
    this.projectSource = sessionStorage.getItem("projectSource");

    if (this.projectSource == "20") {
      this.disabled = true;
      this.disabledcc = true;
    }
    this.getCrowdtypeList();
    this.getareatree();
    if (
      this.auditState == "30" ||
      this.auditState == "20" ||
      this.auditState == "25"
    ) {
      this.disabled = true;
      this.disabledcc = true;
    }
    this.loadData(this.projectId);
    this.getDelayList(this.projectId);
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    childBack(params) {
      this.params = { ...params };
      this.selectType = this.params.trainFormIdPath;
    },
    clearParams() {
      this.params = {};
    },
    getCrowdtypeList() {
      const CrowdtypeList = this.$setDictionary("CROWDTYPE", "list");
      const ProjectFileStyle = this.$setDictionary(
        "PROJECT_FILE_STYLE",
        "list"
      );
      const ProjectTrainMethod = this.$setDictionary(
        "PROJECT_TRAIN_METHOD",
        "list"
      );
      const list = [],
        ProjectFileStyleList = [],
        ProjectTrainMethodList = [];
      for (const key in CrowdtypeList) {
        list.push({
          value: key,
          label: CrowdtypeList[key],
        });
      }
      for (const key in ProjectFileStyle) {
        ProjectFileStyleList.push({
          value: key,
          label: ProjectFileStyle[key],
        });
      }
      for (const key in ProjectTrainMethod) {
        ProjectTrainMethodList.push({
          value: key,
          label: ProjectTrainMethod[key],
        });
      }
      this.CrowdtypeList = list;
      this.ProjectFileStyleList = ProjectFileStyleList;
      this.TrainingMethodsList = ProjectTrainMethodList;
    },
    loadData(projectId) {
      this.$post("/biz/project/query", { projectId })
        .then((ret) => {
          let data = ret.data;
          let res = JSON.stringify(data);
          res = res.replace(/areaId/g, "value");
          res = res.replace(/areaName/g, "label");
          res = res.replace(/trainTypeId/g, "id");
          res = JSON.parse(res);
          // ;
          this.id = res.id;
          this.selectType = res.idPath.substr(0, 1);
          this.Form = {
            projectId: res.projectId,
            projectName: res.projectName,
            Effectivedate: [res.startDateLong, res.endDateLong],
            areaId: res.value,
            deadline: res.signEndDateLong,
            projectUser: res.projectUser,
            projectUserPhone: res.projectUserPhone,
            projectPaperDate: res.projectPaperDateLong,
            projectPaperComp: res.projectPaperComp,
            projectPc: res.projectPc,
            projectAddress: res.projectAddress,
            projectPeople: res.projectPeople,
            thumbnail: res.applicationFormUrl,
            projectCrowd: res.projectCrowd,
            fileKey: res.applicationForm,
            projectClassHourCertificate: res.projectClassHourCertificate,
            enableSurvey: res.enableSurvey,
            auditState: res.auditState,
            auditReason: res.auditReason,
            projectSource: res.projectSource || "",
            trainFormId: res.id,
            trainTypeNamePath: res.trainTypeNamePath,
            spotCheckNum: res.spotCheckNum,
            delay: res.delay,
            chatFileKeys: res.eduProjectFileList || [],
            trainMethod: res.trainMethod,
            uploadfileList: res.list || [],
          };
          let name1 = "";
          let name2 = "";
          let name3 = "";
          let name4 = "";
          let name5 = "";
          if (res.codeBo.trainTypeNamePath) {
            name1 = res.codeBo.trainTypeNamePath;
            this.params.trainFormId = res.codeBo.id;
            this.Form.trainFormId = res.codeBo.id;
          }
          if (res.codeBo.postName) {
            name2 = "/" + res.codeBo.postName;
            this.params.postFormId = res.codeBo.postId;
          }
          if (res.codeBo.industryNamePath) {
            name3 = "/" + res.codeBo.industryNamePath;
            this.params.industryFormId = res.codeBo.industryId;
          }
          if (res.codeBo.occupationNamePath) {
            name4 = "/" + res.codeBo.occupationNamePath;
            this.params.occFormId = res.codeBo.occupationId;
          }
          if (res.codeBo.trainLevelName) {
            name5 = "/" + res.codeBo.trainLevelName;
            this.params.levelFormId = res.codeBo.trainLevelId;
          }
          let name = name1 + name2 + name3 + name4 + name5;
          name = name.replace(/,/g, "-");
          this.Form.Trainingtype = name;
          var timestamp = new Date().getTime();
          if (Number(res.endDateLong) > Number(timestamp)) {
            this.signEndDateDisabled = false;
          } else {
            this.signEndDateDisabled = true;
          }
        })
        .catch((err) => {
          return;
        });
    },
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.Form.thumbnail = result.data.fileURL;
            this.Form.fileKey = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      const el1 = this.$refs.Form1;
      el.validate((valid) => {
        if (valid) {
          if (this.Form.enableSurvey && this.params.trainFormId == "11") {
            el1.validate((valid1) => {
              if (valid1) {
                this.doAjax();
                // if (!this.Form.fileKey) {
                //   this.$confirm("开班申请表未上传，是否上传？", "提示", {
                //     confirmButtonText: "是",
                //     cancelButtonText: "否",
                //     type: "warning",
                //   })
                //     .then(() => {})
                //     .catch(() => {
                //       this.doAjax();
                //     });
                // }
              }
            });
          } else if (
            !this.Form.enableSurvey &&
            this.params.trainFormId == "11"
          ) {
            this.doAjax();
            // if (!this.Form.fileKey) {
            //   this.$confirm("开班申请表未上传，是否上传？", "提示", {
            //     confirmButtonText: "是",
            //     cancelButtonText: "否",
            //     type: "warning",
            //   })
            //     .then(() => {})
            //     .catch(() => {
            //       this.doAjax();
            //     });
            // }
          } else if (
            this.Form.areaId.indexOf("130") != -1 &&
            this.params.trainFormId == "5"
          ) {
            if (this.Form.uploadfileList.length < 1) {
              this.$confirm("开班申请表未上传，是否上传？", "提示", {
                confirmButtonText: "是",
                cancelButtonText: "否",
                showClose: false,
                closeOnClickModal: false,
                type: "warning",
              })
                .then(() => {})
                .catch(() => {
                  this.doAjax();
                });
            } else {
              this.doAjax();
            }
            // if (!this.Form.fileKey) {
            //   this.$confirm("开班申请表未上传，是否上传？", "提示", {
            //     confirmButtonText: "是",
            //     cancelButtonText: "否",
            //     type: "warning",
            //   })
            //     .then(() => {})
            //     .catch(() => {
            //       this.doAjax();
            //     });
            // } else {
            //    this.doAjax();
            // }
          } else {
            this.doAjax();
          }
        }
      });
    },
    // 添加
    doAjax() {
      let parmar = {
        projectName: this.Form.projectName,
        areaId: this.Form.areaId,
        startDate: this.Form.Effectivedate[0],
        endDate: this.Form.Effectivedate[1],
        projectUser: this.Form.projectUser,
        projectUserPhone: this.Form.projectUserPhone,
        projectPeople: this.Form.projectPeople,
        projectClassHourCertificate: this.Form.projectClassHourCertificate,
        trainMethod: "10",
      };
      if (this.Form.deadline) {
        parmar.signEndDate = this.Form.deadline;
      }

      if (this.Form.projectCrowd) {
        parmar.projectCrowd = this.Form.projectCrowd;
      }
      if (this.Form.projectId) {
        parmar.projectId = this.Form.projectId;
      }
      if (this.Form.projectPc) {
        parmar.projectPc = this.Form.projectPc;
      }
      if (this.params.trainFormId) {
        parmar.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmar.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmar.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmar.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmar.industryId = this.params.industryFormId;
      }
      if (this.Form.projectSource) {
        parmar.projectSource = this.Form.projectSource;
      }
      this.$post("/biz/project/hospital/update", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            const name = "classListBj";
            this.$router.push({
              name: name,
              params: {
                refresh: true,
                isAdd: false,
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.$router.push({
        path: "/web/classListBj",
      });
    },
    //延期申请列表
    getDelayList(projectId) {
      this.$post("/edu/project/delay/page", { projectId }).then((ret) => {
        this.tableDelayData = ret.data || [];
      });
    },
    //查看附件
    lookFile(projectDelayId) {
      this.$post("/edu/project/delay/file", { projectDelayId }).then((ret) => {
        console.log(ret);
        this.filedialogVisible = true;
        this.fileList = ret.data || [];
      });
    },
    jumpLook(item) {
      let extension = item.delayFileName.substring(
        item.delayFileName.lastIndexOf(".") + 1
      );
      this.fileType = extension;
      if (this.fileType == "pdf" || this.fileType == "PDF") {
        this.previewLoding1 = true;
        this.$nextTick(() => {
          pdf.embed(item.delayFileKey, "#pdf-cert1");
        });
      } else if (this.fileType == "png" || this.fileType == "jpg") {
        this.previewLoding1 = true;
        this.ImgSrc = item.delayFileKey;
      }
    },
    //行政区划change事件
    areaChange(val) {
      if (val.indexOf("41") == -1) {
        this.Form.trainMethod = "10";
      } else {
        this.Form.trainMethod = "";
      }
    },
    reomveExlUpload(index) {
      this.uploadfileList.splice(index, 1);
      this.$forceUpdate();
    },
    uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.Form.uploadfileList.push({
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileType: extension,
            fileUrl: result.data.fileURL,
          });
        })
        .catch((err) => {
          return;
        });
    },
    reomveExlUpload2(index) {
      this.Form.uploadfileList.splice(index, 1);
      this.$forceUpdate();
    },
    lookExl(item) {
      this.previewLoding1 = true;
      this.fileType = item.fileType;
      console.log("item.fileType", item.fileType);
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        console.log(1);
        setTimeout(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        }, 300);
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
  },
};
</script>
<style lang="less">
.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-item {
    width: 45%;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.framePage-scroll /deep/ .upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
</style>
